<template>
  <div class="search-bar-fr">
    <div class="wrap-inputs">
      <TreeSelect 
        class="device" 
        v-model="selectedDevice" 
        :title="$t('search_device') /*設備*/"
        :data="deviceTreeList" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="false"
        optionWidth="200%"
      />
      <div class="input-set">
        <div class="title">{{ $t('history_fr_name')/*姓名*/ }}</div>
        <input type="text" v-model="name" :placeholder="$t('fr_filter_name_hint')">
      </div>
      <div class="input-set">
        <div class="title">{{ $t('history_fr_id')/*身份識別碼*/ }}</div>
        <input type="text" v-model="idCard" placeholder="Z123456789">
      </div>
      <div class="divider"></div>
      <DateRangeSelect 
        class="dt-select" 
        v-model="frDateTime" 
        :range="true" 
        :title="$t('search_time_interval')"
        :enabledDateStart="enabledDateStart" 
        :enabledDateEnd="enabledDateEnd" 
      />
      <div class="divider"></div>
      <Select 
        class="event-category"
        :class="{ 'show-tag': frEventCategory === 1 }"
        :title="$t('history_fr_event_category')"
        v-model="frEventCategory" 
        :options="frCategoryOptions"
      />
      <Select 
        v-if="frEventCategory === 1"
        class="tag"
        :title="$t('history_fr_tag')"
        :multiSelect="true"
        v-model="frEventTagList" 
        :options="frEventTagOptions"
      />
    </div>
    <div class="wrap-pages-buttons" :class="{ 'no-pages': frEventList.length === 0 }">
      <div v-show="frEventList.length > 0" class="pages">
        <Pagination
          :currentPage="frCurrentPage"
          :total="frEventsTotal"
          :pageSize="frPageSize" 
          mode="single"
          @getPageData="getPageEvents"
        ></Pagination>
      </div>
      <div class="buttons">
        <MagnifierSearchBtn 
          :active="searchLoading"
          @click="searchHistoryFrEvents()"
        />
        <!-- <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_adv_filter')/*進階條件*/" placement="bottom"> -->
          <div class="tool" :class="{ active: isShowAdvSelect }" @click="showAdvSelect">
            <img src="@/assets/icons/filter.svg" alt="">
          </div>
        <!-- </el-tooltip> -->
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
          <div class="tool" @click="earseSearchParams">
            <img src="@/assets/icons/erase-white.svg" alt="">
          </div>
        </el-tooltip>
      </div>
      <div v-if="isShowAdvSelect" class="wrap-adv-select">
        <Select 
          class="event-category"
          :title="$t('search_misjudgment')/*誤判*/"
          v-model="frEventMisjudged" 
          :options="frMisjudgmentOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getFrDateRangeSetting } from '@/config/dateRange.js'

export default {
  name: 'SearchBarFr',
  components: {
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    Select: () => import('@/components/History/SearchBar/Select.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue'),
    MagnifierSearchBtn: () => import('@/components/Base/MagnifierSearchBtn.vue'),
  },
  data() {
    return {
      selectedDevice: null,
      name: '',
      idCard: '',
      frDateTime: [],
      maxRangeDays: 180, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間
      frEventCategory: null,
      frCategoryOptions: [
        { label: this.$t('search_all'/*全部*/), value: null },
        { label: this.$t('tag'/*標記*/), value: 1 },
        { label: this.$t('unknown'/*未知*/), value: 0 },
      ],
      frEventTagList: [],
      isShowAdvSelect: false,
      frEventMisjudged: null,
      frMisjudgmentOptions: [
        { label: this.$t('search_all'/*全部*/), value: null },
        { label: this.$t('search_misjudgment'/*誤判*/), value: 1 },
        { label: this.$t('search_non_misjudgment'/*非誤判*/), value: 0 },
      ],
    }
  },
  computed: {
    ...mapState(['permissionV2', 'frTagList']),
    ...mapState('account', ['deviceTreeList', 'groupList']),
    ...mapState('historyFr', [
      'searchLoading',
      'frPageSize', 
      'frEventsTotal',
      'frCurrentPage',
      'frEventList',
    ]),
    frEventTagOptions() {
      return this.frTagList.map(tag => {
        return { label: tag.name, value: tag.id, locked: tag.locked }
      })
    }
  },
  watch: {
    frEventCategory() {
      this.frEventTagList = []
    },
  },
  mounted() {
    this.initDateRange()
    this.getDeviceTreeList({ public: 0 })
  },
  methods: {
    ...mapMutations('historyFr', ['updateSearchParams']),
    ...mapActions('account', ['getDeviceTreeList']),
    ...mapActions('historyFr', ['getFrEventsTotal', 'searchFrEvents', 'resetHistoryFrData']),
    initDateRange() {
      // 人物事件預設搜尋區間: 預設1週
      // 人物事件最大可搜尋區間: 預設最近三個月
      // 人物事件最多可搜尋的範圍: 預設最近1年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getFrDateRangeSetting()
      this.frDateTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    async getPageEvents(payload) {
      // L0: 無權限, L1: 可存取人物歷史事件
      if (this.permissionV2.frEventAccess === 0) return
      
      if (payload === 0) {
        this.$notify({
          title: this.$t('search_hint')/* 提示 */,
          message: '已在第一頁',
          type: 'warning'
        })
        return
      }
      
      // if (this.frCurrentPage * this.frPageSize < (payload - 1) * this.frPageSize) {
      //   this.$notify({
      //     title: this.$t('search_hint')/* 提示 */,
      //     message: '無下一頁資料',
      //     type: 'warning'
      //   })
      //   return
      // }

      await this.searchFrEvents({ page: payload })
    },
    async searchHistoryFrEvents() {
      // L0: 無權限, L1: 可存取人物歷史事件
      if (this.permissionV2.frEventAccess === 0) return

      this.resetHistoryFrData()
      const params = {}
      // 設備
      if (this.selectedDevice?.id) {
        if (this.selectedDevice.isHasChild) params.group = [this.selectedDevice.groupId]
        else params.user = [this.selectedDevice.id]  
      }
      // 時間區間
      params.afterDetectTime = new Date(this.frDateTime[0]).toISOString()
      params.beforeDetectTime = new Date(this.frDateTime[1]).toISOString()

      // 姓名
      if (this.name) params.name = this.name

      // 身分識別碼
      if (this.idCard) params.idCard = this.idCard

      // 事件類別
      if (this.frEventCategory !== null) params.matched = this.frEventCategory

      // 標記
      if (this.frEventTagList.length > 0) {
        params.tag = this.frEventTagList
      } 

      // 誤判
      if (this.frEventMisjudged !== null) {
        params.misjudged = this.frEventMisjudged
      }

      // 將搜尋參數存入vuex
      this.updateSearchParams(params)
      await this.searchFrEvents({ page: 1 })
    },
    showAdvSelect() {
      this.isShowAdvSelect = !this.isShowAdvSelect
    },
    earseSearchParams() {
      this.selectedDevice = null
      this.name = ''
      this.idCard = ''
      this.frDateTime = []
      this.frEventCategory = null
      this.frEventTagList = []
      this.frEventMisjudged = null
      this.initDateRange()
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search-bar-fr {
  width: 100%;
  height: 135px;  
  padding: px2rem(16) px2rem(20) 0 px2rem(20);
  background: #282942;
  color: #ffffff;
  border-bottom: 1px solid #ffffff33;
}

.wrap-inputs {
  width: 100%;
  display: flex;
  column-gap: px2rem(12);
  margin-bottom: px2rem(8);

  .device {
    max-width: 14.16%;
    min-width: 14.16%;
  }
  
  .input-set {
    width: 14.16%;
    .title {
      @include font_style(px2rem(14), px2rem(21));
      margin-bottom: 4px;
    }
    input {
      width: 100%;
      height: px2rem(36);
      border: none;
      outline: none;
      background: #ffffff;
      border-radius: 8px;
      padding: 0 px2rem(12); 
      @include font_style(px2rem(16), px2rem(24), #191919);
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }

  .divider {
    width: 1px;
    height: 61px;
    background: #ffffff33;
  }

  .dt-select {
    width: 28.76%;
    position: relative;
  }

  .event-category {
    width: 28.76%;
    position: relative;
    &.show-tag {
      width: calc(14.38% - 10px);
    }
  }

  .tag {
    width: calc(14.38% - 10px);
    position: relative;
  }
}
.wrap-pages-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  &.no-pages {
    justify-content: flex-end;
  }

  .pages {
    display: flex;
    align-items: center;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: px2rem(12);
  }

  .tool {
    width: px2rem(36);
    height: px2rem(36);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: #FFFFFF1A;
      
    }

    &.active {
      background: #FFFFFF80;
    } 

    img {
      width: px2rem(22);
      height: px2rem(22);
    }
  }

  .wrap-adv-select {
    width: 194px;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    background: #282942e6;
    border: 1px solid #4A5C78;
    border-radius: 3px;
    padding: 10px 12px 17px 12px;
    z-index: 1;
  }
}

</style>